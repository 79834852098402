module controllers {
    export module customs {
        interface ILineUpdateScope extends ng.IScope {
            consignmentUpdateCtrl: controllers.consigment.ConsignmentExportUpdateCtrl,
        }

        interface ILineUpdateStateParams extends ng.ui.IStateParamsService {
            dciId: number;
        }

        export class goodsDeclarationLineUpdateCtrl {
            static $inject = ["$scope", '$rootScope', 'generalService', '$q', '$state', '$stateParams', '$timeout', "bsLoadingOverlayService", "$anchorScroll",
                "$uibModal", "$transitions", "goodsDeclarationLineService", "lookupService", "countryService", "customsProcedureService",
                "tariffPreferenceCodeService", "packageTypeService", "uiGridConstants", "customsProcedureMatrixService", "classificationValueService", "statisticalUnitService",
                "additionalInfoService", "taxTypeService", "dutyTypeService","hsCodeService" ,"menuService","version", "gsdId"];

            selectedTab: number = 0;

            goodsDeclarationLine: interfaces.customs.IGoodsDeclarationLine;

            breadCrumbDesc: string;

            isValidTariffCode: boolean  = true;

            allowTariffInvoice: boolean;

            isManual: boolean = false;

            constructor(private $scope: ILineUpdateScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $state: ng.ui.IStateService,
                private $stateParams: ILineUpdateStateParams,
                private $timeout: ng.ITimeoutService,
                private bsLoadingOverlayService: interfaces.applicationcore.IbsLoadingOverlayService,
                private $anchorScroll: ng.IAnchorScrollService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private $transitions: ng.ui.core.ITransition,
                private goodsDeclarationLineService: interfaces.customs.IGoodsDeclarationLineService,
                private lookupService: interfaces.applicationcore.ILookupService,
                private countryService: interfaces.applicationcore.ICountryService,
                private customsProcedureService: interfaces.tariff.ICustomsProcedureService,
                private tariffPreferenceCodeService: interfaces.tariff.ITariffPreferenceCodeService,
                private packageTypeService: interfaces.applicationcore.IPackageTypeService,
                private uiGridConstants: uiGrid.IUiGridConstants,
                private customsProcedureMatrixService: interfaces.tariff.ICustomsProcedureMatrixService,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                private statisticalUnitService: interfaces.tariff.IStatisticalUnitService,
                private additionalInfoService: interfaces.tariff.IAdditionalInfoService,
                private taxTypeService: interfaces.tariff.ITaxTypeService,
                private dutyTypeService: interfaces.tariff.IDutyTypeService,
                private hsCodeService: interfaces.tariff.IHsCodeService,
                private menuService: interfaces.applicationcore.IMenuService,
                private version: number,
                private gsdId: number,
                ) {

                bsLoadingOverlayService.wrap({
                    referenceId: 'con.update.dci'
                }, () => {
                    return this.loadGoodsDecLine();
                });
            }

            loadGoodsDecLine(dciId?:number) {
                return this.goodsDeclarationLineService.get(this.gsdId, dciId?dciId: this.$stateParams.dciId, this.version).get((result) => {
                    this.goodsDeclarationLine = result;

                    if (this.goodsDeclarationLine) {
                        this.breadCrumbDesc = this.goodsDeclarationLine.SequenceNumber + " - " + this.goodsDeclarationLine.CommodityCode?this.goodsDeclarationLine.CommodityCode:'New Line';

                        this.gvwAdditionalCodes.data = this.goodsDeclarationLine.AdditionalCodeList;
                        this.gvwAdditionalInfos.data = this.goodsDeclarationLine.AdditionalInfoList;
                        this.gvwQuantities.data = this.goodsDeclarationLine.QuantityList;
                        this.gvwDuties.data = this.goodsDeclarationLine.DutiesList;
                        
                        if (this.goodsDeclarationLine.Source === 'MANUAL'){
                            this.isManual = true;
                        }
                        else{
                            this.isManual = false;
                        }

                        this.getAllowTariffInvoiceRight();

                        this.$timeout(() => {
                            if (this.apiAdditionalCodes) {
                                this.apiAdditionalCodes.selection.clearSelectedRows();
                                this.apiAdditionalCodes.core.notifyDataChange(this.uiGridConstants.dataChange.ALL);
                            }

                            if (this.apiAdditionalInfos) {
                                this.apiAdditionalInfos.core.notifyDataChange(this.uiGridConstants.dataChange.ALL);
                            }

                            if (this.apiQuantities) {
                                this.apiQuantities.core.notifyDataChange(this.uiGridConstants.dataChange.ALL);
                            }
                        });
                    }

                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            getAllowTariffInvoiceRight() {
                this.allowTariffInvoice = false;                
                return this.menuService.getGTSConnectMenuItem(this.$scope.consignmentUpdateCtrl.consignment.IsInbound?654:653).get((result: interfaces.applicationcore.IMenu) => {
                    if (result.name) {
                        this.allowTariffInvoice = true;
                    }
                }).$promise;
            }


            commodityCodeLookup_click() {
                this.isValidTariffCode = false;
                this.lookupService.openTariffLookup(this.goodsDeclarationLine.DeclarationCountryId, Enum.EnumTariffCodeType.NationMeasure, false, this.goodsDeclarationLine.CommodityCode,this.$scope.consignmentUpdateCtrl.consignment.OwnerEntityId,Enum.EnumTariffCodeType.NationMeasure).result.then(
                    (result: interfaces.tariff.INationalMeasureCodeLookup) => {

                        if (result.CodeTypeClassificationValueId !== Enum.EnumTariffCodeType.NationMeasure) {
                            this.goodsDeclarationLine.CommodityCode = "";
                            this.isValidTariffCode = false;
                        } else {
                            this.isValidTariffCode = true;
                            if (this.goodsDeclarationLine.CommodityCode != result.NationalMeasureCode)
                            {
                                this.goodsDeclarationLine.CommodityCode = result.NationalMeasureCode;
                                this.goodsDeclarationLine.DescriptionOfGoods = result.Description;
                            }
                        }

                        if (this.goodsDeclarationLine) {
                            this.breadCrumbDesc = this.goodsDeclarationLine.SequenceNumber + " - " + this.goodsDeclarationLine.CommodityCode;
                        }
                    }, (errorResult) => {

                    });
            }

            procedureMeasureCodeLookup_click() {
                this.lookupService.openTariffLookup(this.goodsDeclarationLine.DeclarationCountryId, Enum.EnumTariffCodeType.ProcedureMeasure, false, this.goodsDeclarationLine.ProcedureMeasureCode,this.$scope.consignmentUpdateCtrl.consignment.OwnerEntityId,Enum.EnumTariffCodeType.ProcedureMeasure).result.then(
                    (result: interfaces.tariff.INationalMeasureCodeLookup) => {

                        if (result.CodeTypeClassificationValueId !== Enum.EnumTariffCodeType.ProcedureMeasure) {
                            this.goodsDeclarationLine.ProcedureMeasureCode = "";
                            this.goodsDeclarationLine.ProcedureMeasureDescription = "";
                        } else {
                            this.goodsDeclarationLine.ProcedureMeasureCode = result.NationalMeasureCode;
                            this.goodsDeclarationLine.ProcedureMeasureDescription = result.Description;
                        }
                    }, (errorResult) => {

                    });
            }

            loadCountries() {
                return this.countryService.getDropdownList().query({
                },
                    (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
            }

            loadCustomProcedures(searchText: string) {
                return this.customsProcedureService.getDropdownList(this.goodsDeclarationLine.DeclarationCountryId, searchText).query({
                },
                    (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;

            }

            loadPreviousCustomsProcedure() {
                return this.customsProcedureMatrixService.getPrevCustomsProcedureDropdownList(this.goodsDeclarationLine.DeclarationCountryId, this.goodsDeclarationLine.ProcedureCategory.Id, this.goodsDeclarationLine.CustomsProcedure.Id).query({
                }, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                }, (errorResponse) => {                                
                }).$promise;
            }

            previousCustomsProcedureChanged(model: interfaces.applicationcore.IDropdownModel) {
                if (!model) {
                    return;
                }
                this.goodsDeclarationLine.PrevCustomsProcedureId = model.Id;
            }

            customsProcedureChanged(model: interfaces.applicationcore.IDropdownModel) {
                if (!this.goodsDeclarationLine.ProcedureCategory) {
                    return;
                }

                this.customsProcedureMatrixService.get(
                    this.goodsDeclarationLine.DeclarationCountryId,
                    this.goodsDeclarationLine.ProcedureCategory.Id,
                    model ? model.Id : undefined,
                    this.goodsDeclarationLine.PrevCustomsProcedureId ).get(
                    (result: interfaces.tariff.ICustomsProcedureMatrix) => {
                        this.goodsDeclarationLine.IsDutiable = result.Dutible;
                        this.goodsDeclarationLine.IsExcisable = result.IsExcisable;

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    })
            }

            loadPreferenceCodes(searchText: string) {
                return this.tariffPreferenceCodeService.getDropdownList().query({
                    tariffCountryId: this.goodsDeclarationLine.DeclarationCountryId
                },
                    (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
            }

            loadPackageTypes(searchText: string) {
                return this.packageTypeService.getDropdownList(searchText).query((list: Array<interfaces.applicationcore.IDropdownModel>) => {
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            apiAdditionalCodes: uiGrid.IGridApi;
            gvwAdditionalCodes: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                showGridFooter: true,
                onRegisterApi: (gridApi) => {
                    this.apiAdditionalCodes = gridApi;

                    this.apiAdditionalCodes.cellNav.on.navigate(this.$scope, (gridSelection) => {
                        this.apiAdditionalCodes.selection.selectRow(gridSelection.row.entity);
                    });
                },
                columnDefs: [{
                    name: "EDIT",
                    field: "Id",
                    displayName: "",
                    enableFiltering: false,
                    width: 32,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    cellTemplate: `
                    <div class="GridButton"> 
                        <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.dciUpdateCtrl.editAddCode(row.entity)" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0">
                            <span class="fa fa-pencil"></span>
                        </button>
                    </div>`,
                    enableColumnMenu: false,
                    headerCellTemplate: `
                    <div class="GridButton"> 
                        <button type="button" class="btn btn-default btn-sm"  ng-click="grid.appScope.dciUpdateCtrl.addAddCode()" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0">
                            <span class="fa fa-plus"></span>
                        </button>
                    </div>`
                }, {
                    name: "DEL",
                    field: "DELETE",
                    displayName: "",
                    enableFiltering: false,
                    width: 70,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    cellTemplate: `
                    <div class="GridButton"> 
                        <button type="button" class="btn btn-warning btn-sm" ng-click="grid.appScope.dciUpdateCtrl.deleteAddCode(row.entity)" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0">
                            DELETE
                        </button>
                    </div>`,
                    enableColumnMenu: false
                }, {
                    name: "ADDCODE",
                    displayName: "Additional Code",
                    field: "AdditionalCode",
                    width: 150,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    filterHeaderTemplate: `
                                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <div class="input-group-sm">
                                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                            </div>`
                }]
            };

            editAddCode(entity: interfaces.customs.IGoodsDeclarationLineAdditionalCodeDisplay) {
                this.lookupService.openTariffLookup(this.goodsDeclarationLine.DeclarationCountryId, Enum.EnumTariffCodeType.AdditionalCode, false, entity.AdditionalCode,this.$scope.consignmentUpdateCtrl.consignment.OwnerEntityId,Enum.EnumTariffCodeType.AdditionalCode).result.then(
                    (result: interfaces.tariff.INationalMeasureCodeLookup) => {

                        if (result.CodeTypeClassificationValueId === Enum.EnumTariffCodeType.AdditionalCode && entity.AdditionalCode !== result.NationalMeasureCode) {
                            entity.AdditionalCode = result.NationalMeasureCode;

                            this.$timeout(() => {
                                if (this.apiAdditionalCodes) {
                                    this.apiAdditionalCodes.rowEdit.setRowsDirty([entity]);
                                    this.apiAdditionalCodes.core.notifyDataChange(this.uiGridConstants.dataChange.ALL);
                                }
                            });
                        }
                    }, (errorResult) => {

                    });
            }

            addAddCode() {
                this.lookupService.openTariffLookup(this.goodsDeclarationLine.DeclarationCountryId, Enum.EnumTariffCodeType.AdditionalCode, false,null,this.$scope.consignmentUpdateCtrl.consignment.OwnerEntityId,Enum.EnumTariffCodeType.AdditionalCode).result.then(
                    (result: interfaces.tariff.INationalMeasureCodeLookup) => {

                        if (result.CodeTypeClassificationValueId === Enum.EnumTariffCodeType.AdditionalCode) {
                            var newAddCode = {
                                AdditionalCode: result.NationalMeasureCode
                            };

                            (<Array<any>>(this.gvwAdditionalCodes.data)).splice(0, 0, newAddCode);

                            this.$timeout(() => {
                                if (this.apiAdditionalCodes) {
                                    this.apiAdditionalCodes.rowEdit.setRowsDirty([newAddCode]);
                                    this.apiAdditionalCodes.core.notifyDataChange(this.uiGridConstants.dataChange.ALL);
                                }
                            });
                        }
                    }, (errorResult) => {

                    });
            }

            deleteAddCode(entity: interfaces.customs.IGoodsDeclarationLineAdditionalCodeDisplay) {
                if (!entity.Id) {
                    var index = (<Array<interfaces.customs.IGoodsDeclarationLineAdditionalCodeDisplay>>this.gvwAdditionalCodes.data).indexOf(entity);
                    (<Array<interfaces.customs.IGoodsDeclarationLineAdditionalCodeDisplay>>this.gvwAdditionalCodes.data).splice(index, 1);

                    this.$timeout(() => {
                        if (this.apiAdditionalCodes) {
                            this.apiAdditionalCodes.core.notifyDataChange(this.uiGridConstants.dataChange.ALL);
                        }
                    });

                } else if (entity.Id > 0) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'con.update.dci'
                    },
                        () => {

                            return this.goodsDeclarationLineService.removeAddCode(entity.Id).delete((messageHandler: interfaces.applicationcore.IMessageHandler) => {
                                this.generalService.displayMessageHandler(messageHandler);

                                this.$timeout(() => {
                                    this.bsLoadingOverlayService.wrap({
                                        referenceId: 'con.update.dci'
                                    }, () => {
                                        return this.loadGoodsDecLine();
                                    });
                                });

                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                        });
                }
            }

            showCustomsFactorModal() {
                return this.$uibModal.open({
                    animation: true,
                    template: `<div class="modal-content">
                                <div class="modal-header">
                                    <h3 class="modal-title" id="modal-title">Customs Factor</h3>
                                </div>
                                <div class="modal-body" id="modal-body">
                                    <div class="row">
                                        <div class="vertical-align">
                                            <div class="col-md-12">
                                                <div id="gridTest" ui-grid="gvwCustomsFactor" ui-grid-resize-columns ui-grid-cellNav class="gridSmallSmall"
                                                     ng-if="gvwCustomsFactor">
                                                    <div layout="column" layout-align="center center" class="grid-msg-overlay" ng-if="IsLoading">
                                                        <div class="loadingGrid" ng-show="IsLoading">
                                                            <i class="fa fa-spinner fa-spin"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button class="btn btn-info" type="button" ng-click="close()">Close</button>
                                </div>
                            </div>`,
                    controller: ($scope, $uibModalInstance: angular.ui.bootstrap.IModalInstanceService, containerService: interfaces.logistics.IContainerService) => {


                        $scope.close = () => {
                            $uibModalInstance.close(true);
                        };

                        $scope.gvwCustomsFactor = {
                            data: [],
                            enableFiltering: false,
                            useExternalFiltering: false,
                            enableCellEdit: false,
                            useExternalSorting: false,
                            multiSelect: false,
                            enableColumnResizing: true,
                            enableFullRowSelection: false,
                            useExternalPagination: true,
                            enableRowHeaderSelection: false,
                            enableHorizontalScrollbar: 2,
                            enableVerticalScrollbar: 1,
                            onRegisterApi: (gridApi) => {
                                $scope.gvwCustomsFactor.data = this.goodsDeclarationLine.GoodsDeclarationLineCustomsFactorList;

                                this.$timeout(() => {
                                    if (gridApi) {
                                        gridApi.core.notifyDataChange(this.uiGridConstants.dataChange.ALL);
                                    }
                                });
                            },
                            columnDefs: [{
                                name: "COMNUM",
                                displayName: "Commercial Invoice",
                                field: "CommercialInvoiceNumber",
                                type: "string",
                                width: 150
                            }, {
                                name: "PRODCODE",
                                displayName: "Product Code",
                                field: "ProductCode",
                                type: "string",
                                width: 125
                            }, {
                                name: "PRODDESC",
                                displayName: "Product Description",
                                field: "ProductDescription",
                                type: "string",
                                width: 150
                            }, {
                                name: "CUSVAL",
                                displayName: "Customs Value",
                                field: "CustomsValue",
                                type: "number",
                                cellFilter: "number: 2",
                                width: 150
                            }, {
                                name: "CUSFAC",
                                displayName: "Customs Factor",
                                field: "CustomsFactor",
                                type: "number",
                                cellFilter: "number: 6",
                                width: 150
                            }]
                        };
                    },
                    resolve: {
                    }
                }).result.then(() => { }, () => { });
            }

            apiQuantities: uiGrid.IGridApi;
            gvwQuantities: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                showGridFooter: true,
                onRegisterApi: (gridApi) => {
                    this.apiQuantities = gridApi;

                    this.apiQuantities.cellNav.on.navigate(this.$scope, (gridSelection) => {
                        this.apiQuantities.selection.selectRow(gridSelection.row.entity);
                    });
                },
                columnDefs: [{
                    name: "DEL",
                    field: "DELETE",
                    displayName: "",
                    enableFiltering: false,
                    width: 70,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    cellTemplate: `
                    <div class="GridButton"> 
                        <button type="button" class="btn btn-warning btn-sm" ng-click="grid.appScope.dciUpdateCtrl.deleteQuantity(row.entity)" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0">
                            DELETE
                        </button>
                    </div>`,
                    enableColumnMenu: false,
                    headerCellTemplate: `
                    <div class="GridButton"> 
                        <button type="button" class="btn btn-default btn-sm"  ng-click="grid.appScope.dciUpdateCtrl.addQuantity()" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0">
                            <span class="fa fa-plus"></span>
                        </button>`
                }, {
                    name: "STATTYPE",
                    displayName: "Statistical Unit Type",
                    field: "TypeClassificationValue",
                    enableCellEditOnFocus: true,
                    enableCellEdit: true,
                    width: 200,
                    cellTemplate: `<div class="ui-grid-cell-contents" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0">{{MODEL_COL_FIELD.Display}}</div>`,
                    editableCellTemplate: `<form name="inputForm">
                                                <gts-dropdown ui-select-wrap name="country-{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.dciUpdateCtrl.loadStatisticalUnitType(searchText)" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0"></gts-dropdown>
                                            </form>`,
                    filterHeaderTemplate: `
                                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                        <div class="input-group-sm">
                                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                    </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return (searchTerm.length === 0 || cellValue.Code.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || cellValue.Description.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0);
                        }
                    },
                    sortingAlgorithm: (a, b, rowA, rowB, direction) => {
                        var nulls = this.apiQuantities.core.sortHandleNulls(a, b);
                        if (nulls !== null) {
                            return nulls;
                        } else {
                            if (a === b) {
                                return 0;
                            }

                            if (direction === "desc") {
                                if (a.Code < b.Code) return -1;
                                if (a.Code > b.Code) return 1;
                            } else if (direction === "asc") {
                                if (a.Code > b.Code) return 1;
                                if (a.Code < b.Code) return -1;
                            }

                            return 0;
                        }
                    }
                }, {
                    name: "STATUNIT",
                    displayName: "Statistical Unit",
                    field: "StatisticalUnit",
                    enableCellEditOnFocus: true,
                    enableCellEdit: true,
                    width: 200,
                    cellTemplate: `<div class="ui-grid-cell-contents" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0">{{MODEL_COL_FIELD.Display}}</div>`,
                    editableCellTemplate: `<form name="inputForm">
                                                <gts-dropdown ui-select-wrap name="country-{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.dciUpdateCtrl.loadStatUnits(searchText)" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0"></gts-dropdown>
                                            </form>`,
                    filterHeaderTemplate: `
                                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                        <div class="input-group-sm">
                                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                    </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return (searchTerm.length === 0 || cellValue.Code.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || cellValue.Description.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0);
                        }
                    },
                    sortingAlgorithm: (a, b, rowA, rowB, direction) => {
                        var nulls = this.apiQuantities.core.sortHandleNulls(a, b);
                        if (nulls !== null) {
                            return nulls;
                        } else {
                            if (a === b) {
                                return 0;
                            }

                            if (direction === "desc") {
                                if (a.Code < b.Code) return -1;
                                if (a.Code > b.Code) return 1;
                            } else if (direction === "asc") {
                                if (a.Code > b.Code) return 1;
                                if (a.Code < b.Code) return -1;
                            }

                            return 0;
                        }
                    }
                }, {
                    name: "QTY",
                    displayName: "Quantity",
                    field: "Quantity",
                    width: 150,
                    cellClass: 'text-right',
                    cellFilter: 'number: 6',
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    filterHeaderTemplate: `
                                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                    <div class="input-group-sm">
                                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }" /></div>
                                                </div>`,
                    editableCellTemplate: `
                                <form name="inputForm" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0">
                                    <p class="input-group-sm" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0">
                                        <input type="number" ignore-wheel name="{{MODEL_COL_FIELD.$$hashKey}}-desc" autocomplete="off" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.000001" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0"/>
                                    </p>
                                </form>`,
                    cellEditableCondition: (scope) => { return scope.row.entity.StatisticalUnit; }
                },
                {
                    name: "IsActive",
                    displayName: "Active",
                    field: "IsActive",
                    width: 60,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    enableFiltering: false,
                    cellTemplate: `<div class="ui-grid-cell-contents" ng-disabled="grid.appScope.GoodsDeclaration.LockdownType > 0">{{MODEL_COL_FIELD ? 'Yes' : 'No'}}</div>`,
                    editableCellTemplate: ` <form name="inputForm">
                                                    <p class="input-group-sm">
                                                        <input type="checkbox" name="{{MODEL_COL_FIELD.$$hashKey}}-desc" ng-click="grid.appScope.dciUpdateCtrl.QuantityIsDirty(row.entity)" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0" ng-model="MODEL_COL_FIELD">
                                                    </p>
                                                </form>`,
                }
                ]
            };

            QuantityIsDirty(quantityEntity: interfaces.customs.IGoodsDeclarationLineQuantityDisplay) {
                this.apiQuantities.rowEdit.setRowsDirty([quantityEntity]);
            }

            addQuantity() {

                this.goodsDeclarationLine.QuantityList.push(<interfaces.customs.IGoodsDeclarationLineQuantityDisplay>{
                    Id: 0,
                    IsActive: true
                });
                
                this.gvwQuantities.data = this.goodsDeclarationLine.QuantityList;

                this.$timeout(() => {
                    if (this.apiQuantities) {
                        this.apiQuantities.core.notifyDataChange(this.uiGridConstants.dataChange.ALL);
                    }
                });
            }

            deleteQuantity(entity: interfaces.customs.IGoodsDeclarationLineQuantityDisplay) {
                if (!entity.Id) {
                    var index = (<Array<interfaces.customs.IGoodsDeclarationLineQuantityDisplay>>this.gvwQuantities.data).indexOf(entity);
                    (<Array<interfaces.customs.IGoodsDeclarationLineQuantityDisplay>>this.gvwQuantities.data).splice(index, 1);

                    this.$timeout(() => {
                        if (this.apiQuantities) {
                            this.apiQuantities.core.notifyDataChange(this.uiGridConstants.dataChange.ALL);
                        }
                    });

                } else if (entity.Id > 0) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'con.update.dci'
                    },
                        () => {

                            return this.goodsDeclarationLineService.removeQuantity(entity.Id).delete((messageHandler: interfaces.applicationcore.IMessageHandler) => {
                                this.generalService.displayMessageHandler(messageHandler);

                                this.$timeout(() => {
                                    this.bsLoadingOverlayService.wrap({
                                        referenceId: 'con.update.dci'
                                    }, () => {
                                        return this.loadGoodsDecLine();
                                    });
                                });

                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                        });
                }
            }

            loadStatisticalUnitType() {
                return this.classificationValueService.GetClassificationValueDropdownByClassification().query(
                    {
                        classificationId: 104
                    }, () => { }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
            }

            loadStatUnits() {
                return this.statisticalUnitService.getDropdownList(this.goodsDeclarationLine.DeclarationCountryId).query(() => { }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            calculateQuantities() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'con.update.dci'
                }, () => {
                    return this.SaveAndCalculateQuantities().then((result) => {
                        if (!result.HasErrorMessage) {
                            this.$timeout(() => {
                                this.bsLoadingOverlayService.wrap({
                                    referenceId: 'con.update.dci'
                                }, () => {
                                    return this.loadGoodsDecLine();
                                });
                            });
                        }
                    });
                });
            }

            apiAdditionalInfos: uiGrid.IGridApi;
            gvwAdditionalInfos: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                showGridFooter: true,
                onRegisterApi: (gridApi) => {
                    this.apiAdditionalInfos = gridApi;

                    this.apiAdditionalInfos.cellNav.on.navigate(this.$scope, (gridSelection) => {
                        this.apiAdditionalInfos.selection.selectRow(gridSelection.row.entity);
                    });
                },
                columnDefs: [{
                    name: "DEL",
                    field: "DELETE",
                    displayName: "",
                    enableFiltering: false,
                    width: 70,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    cellTemplate: `
                    <div class="GridButton"> 
                        <button type="button" class="btn btn-warning btn-sm" ng-click="grid.appScope.dciUpdateCtrl.deleteAddInfo(row.entity)" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0">
                            DELETE
                        </button>
                    </div>`,
                    enableColumnMenu: false,
                    headerCellTemplate: `
                    <div class="GridButton"> 
                        <button type="button" class="btn btn-default btn-sm"  ng-click="grid.appScope.dciUpdateCtrl.addAddinfo()" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0">
                            <span class="fa fa-plus"></span>
                        </button>`
                }, {
                    name: "ADDINFO",
                    displayName: "Code",
                    field: "AdditionalInformation",
                    enableCellEditOnFocus: true,
                    enableCellEdit: true,
                    width: 125,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Display}}</div>`,
                    editableCellTemplate: `<form name="inputForm">
                                                <gts-dropdown ui-select-wrap name="country-{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.dciUpdateCtrl.loadAdditionalInfos(searchText)" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0"></gts-dropdown>
                                            </form>`,
                    filterHeaderTemplate: `
                                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                        <div class="input-group-sm">
                                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                    </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return (searchTerm.length === 0 || cellValue.Code.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || cellValue.Description.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0);
                        }
                    },
                    sortingAlgorithm: (a, b, rowA, rowB, direction) => {
                        var nulls = this.apiQuantities.core.sortHandleNulls(a, b);
                        if (nulls !== null) {
                            return nulls;
                        } else {
                            if (a === b) {
                                return 0;
                            }

                            if (direction === "desc") {
                                if (a.Code < b.Code) return -1;
                                if (a.Code > b.Code) return 1;
                            } else if (direction === "asc") {
                                if (a.Code > b.Code) return 1;
                                if (a.Code < b.Code) return -1;
                            }

                            return 0;
                        }
                    }
                }, {
                    name: "ADDINFODESC",
                    displayName: "Description",
                    field: "AdditionalInformation.Description",
                    width: 200,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    filterHeaderTemplate: `
                                                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                                        <div class="input-group-sm">
                                                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                                    </div>`,
                        cellTemplate: `<div class="ui-grid-cell-contents" uib-popover="{{MODEL_COL_FIELD}}" popover-enable="{{MODEL_COL_FIELD.length >= 50}}" popover-trigger="'mouseenter'" popover-append-to-body="true" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0">{{MODEL_COL_FIELD}}</div>`,
                }, {
                    name: "ADDINFOVALUE",
                    displayName: "Value",
                    field: "AdditionalInformationValue",
                    width: 150,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    filterHeaderTemplate: `
                                                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                                        <div class="input-group-sm">
                                                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                                    </div>`,
                    editableCellTemplate: `
                                <form name="inputForm" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0">
                                    <p class="input-group-sm" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0">
                                        <input type="text" name="{{MODEL_COL_FIELD.$$hashKey}}-val" autocomplete="off" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor maxlength="70" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0"/>
                                    </p>
                                </form>`
                }]
            };

            loadAdditionalInfos() {
                return this.additionalInfoService.getDropdownList(this.goodsDeclarationLine.DeclarationCountryId).query(() => { }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            addAddinfo() {
                (<Array<any>>(this.gvwAdditionalInfos.data)).splice(0, 0, {});

                this.$timeout(() => {
                    if (this.apiAdditionalInfos) {
                        this.apiAdditionalInfos.core.notifyDataChange(this.uiGridConstants.dataChange.ALL);
                    }
                });
            }

            deleteAddInfo(entity: interfaces.customs.IGoodsDeclarationLineQuantityDisplay) {
                if (!entity.Id) {
                    var index = (<Array<interfaces.customs.IGoodsDeclarationLineQuantityDisplay>>this.gvwAdditionalInfos.data).indexOf(entity);
                    (<Array<interfaces.customs.IGoodsDeclarationLineQuantityDisplay>>this.gvwAdditionalInfos.data).splice(index, 1);

                    this.$timeout(() => {
                        if (this.apiAdditionalInfos) {
                            this.apiAdditionalInfos.core.notifyDataChange(this.uiGridConstants.dataChange.ALL);
                        }
                    });

                } else if (entity.Id > 0) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'con.update.dci'
                    },
                        () => {

                            return this.goodsDeclarationLineService.removeAddInfo(entity.Id).delete((messageHandler: interfaces.applicationcore.IMessageHandler) => {
                                this.generalService.displayMessageHandler(messageHandler);

                                this.$timeout(() => {
                                    this.bsLoadingOverlayService.wrap({
                                        referenceId: 'con.update.dci'
                                    }, () => {
                                        return this.loadGoodsDecLine();
                                    });
                                });

                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                        });
                }
            }

            apiDuties: uiGrid.IGridApi;
            gvwDuties: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                showGridFooter: true,
                onRegisterApi: (gridApi) => {
                    this.apiDuties = gridApi;

                    this.apiDuties.cellNav.on.navigate(this.$scope, (gridSelection) => {
                        this.apiDuties.selection.selectRow(gridSelection.row.entity);
                    });
                },
                columnDefs: [{
                    name: "DEL",
                    field: "DELETE",
                    displayName: "",
                    enableFiltering: false,
                    width: 70,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    cellTemplate: `
                    <div class="GridButton"> 
                        <button type="button" class="btn btn-warning btn-sm" ng-click="grid.appScope.dciUpdateCtrl.deleteDuty(row.entity)" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0">
                            DELETE
                        </button>
                    </div>`,
                    enableColumnMenu: false,
                    headerCellTemplate: `
                    <div class="GridButton"> 
                        <button type="button" class="btn btn-default btn-sm"  ng-click="grid.appScope.dciUpdateCtrl.addDutyType()" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0">
                            <span class="fa fa-plus"></span>
                        </button>`
                }, {
                    name: "TAXTYPE",
                    displayName: "Tax Type",
                    field: "TaxType",
                    enableCellEditOnFocus: true,
                    enableCellEdit: true,
                    width: 250,
                    cellTemplate: `<div class="ui-grid-cell-contents" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0">{{MODEL_COL_FIELD.Display}}</div>`,
                    editableCellTemplate: `<form name="inputForm" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0">
                                                <gts-dropdown ui-select-wrap name="tax-{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.dciUpdateCtrl.loadTaxTypes(searchText)" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0"></gts-dropdown>
                                            </form>`,
                    filterHeaderTemplate: `
                                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                        <div class="input-group-sm">
                                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                    </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return (searchTerm.length === 0 || cellValue.Code.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || cellValue.Description.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0);
                        }
                    },
                    sortingAlgorithm: (a, b, rowA, rowB, direction) => {
                        var nulls = this.apiQuantities.core.sortHandleNulls(a, b);
                        if (nulls !== null) {
                            return nulls;
                        } else {
                            if (a === b) {
                                return 0;
                            }

                            if (direction === "desc") {
                                if (a.Code < b.Code) return -1;
                                if (a.Code > b.Code) return 1;
                            } else if (direction === "asc") {
                                if (a.Code > b.Code) return 1;
                                if (a.Code < b.Code) return -1;
                            }

                            return 0;
                        }
                    }
                }, {
                    name: "DUTYTYPE",
                    displayName: "Duty Type",
                    field: "DutyType",
                    enableCellEditOnFocus: true,
                    enableCellEdit: true,
                    width: 250,
                    cellTemplate: `<div class="ui-grid-cell-contents" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0">{{MODEL_COL_FIELD.Display}}</div>`,
                    editableCellTemplate: `<form name="inputForm">
                                                <gts-dropdown ui-select-wrap name="country-{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.dciUpdateCtrl.loadDutyTypes(searchText)" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0"></gts-dropdown>
                                            </form>`,
                    filterHeaderTemplate: `
                                                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                        <div class="input-group-sm">
                                                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                    </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {
                            return (searchTerm.length === 0 || cellValue.Code.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || cellValue.Description.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0);
                        }
                    },
                    sortingAlgorithm: (a, b, rowA, rowB, direction) => {
                        var nulls = this.apiQuantities.core.sortHandleNulls(a, b);
                        if (nulls !== null) {
                            return nulls;
                        } else {
                            if (a === b) {
                                return 0;
                            }

                            if (direction === "desc") {
                                if (a.Code < b.Code) return -1;
                                if (a.Code > b.Code) return 1;
                            } else if (direction === "asc") {
                                if (a.Code > b.Code) return 1;
                                if (a.Code < b.Code) return -1;
                            }

                            return 0;
                        }
                    }
                }, {
                    name: "DUTYVALUE",
                    displayName: "Duty Value",
                    field: "DutyValue",
                    width: 150,
                    cellClass: 'text-right',
                    cellFilter: 'number: 2',
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    filterHeaderTemplate: `
                                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                <div class="input-group-sm">
                                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                            </div>`,
                    editableCellTemplate: `
                                <form name="inputForm" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0">
                                    <p class="input-group-sm" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0">
                                        <input type="number" ignore-wheel name="{{MODEL_COL_FIELD.$$hashKey}}-desc" autocomplete="off" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.01" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0"/>
                                    </p>
                                </form>`
                }, {
                    name: "FORMULA",
                    displayName: "Formula",
                    field: "Formula",
                    width: 200,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    filterHeaderTemplate: `
                                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                    <div class="input-group-sm">
                                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                </div>`,
                }, {
                    name: "DUTYDUE",
                    displayName: "Duties Due",
                    field: "DutiesDue",
                    width: 150,
                    cellClass: 'text-right',
                    cellFilter: 'number: 2',
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    filterHeaderTemplate: `
                                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                    <div class="input-group-sm">
                                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                </div>`,
                    editableCellTemplate: `
                                <form name="inputForm" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0">
                                    <p class="input-group-sm" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0">
                                        <input type="number" ignore-wheel name="{{MODEL_COL_FIELD.$$hashKey}}-desc" autocomplete="off" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.01" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0"/>
                                    </p>
                                </form>`
                }, {
                    name: "DUTYREF",
                    displayName: "Duty Refund",
                    field: "DutyRefund",
                    width: 150,
                    cellClass: 'text-right',
                    cellFilter: 'number: 2',
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    filterHeaderTemplate: `
                                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                    <div class="input-group-sm">
                                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                </div>`,
                    editableCellTemplate: `
                                <form name="inputForm" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0">
                                    <p class="input-group-sm" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0">
                                        <input type="number" ignore-wheel name="{{MODEL_COL_FIELD.$$hashKey}}-desc" autocomplete="off" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.01" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0"/>
                                    </p>
                                </form>`
                 }, {
                        name: "BONDVAL",
                        displayName: "Bond Value",
                        field: "BondValue",
                        width: 150,
                        cellClass: 'text-right',
                        cellFilter: 'number: 2',
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        filterHeaderTemplate: `
                                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                                    <div class="input-group-sm">
                                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                                </div>`,
                        editableCellTemplate: `
                                <form name="inputForm" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0">
                                    <p class="input-group-sm" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0">
                                        <input type="number" ignore-wheel name="{{MODEL_COL_FIELD.$$hashKey}}-desc" autocomplete="off" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor step="0.01" ng-disabled="grid.appScope.goodsDeclarationUpdateCtrl.GoodsDeclaration.LockdownType > 0"/>
                                    </p>
                                </form>`
                    }]
            };

            loadTaxTypes() {
                return this.taxTypeService.getDropdownList(this.goodsDeclarationLine.DeclarationCountryId).query(() => { }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            loadDutyTypes() {
                return this.dutyTypeService.getDropdownList(this.goodsDeclarationLine.DeclarationCountryId).query(() => { }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            addDutyType() {
                (<Array<any>>(this.gvwDuties.data)).splice(0, 0, {
                    Formula: 'ManualCalculation'
                });

                this.$timeout(() => {
                    if (this.apiAdditionalInfos) {
                        this.apiAdditionalInfos.core.notifyDataChange(this.uiGridConstants.dataChange.ALL);
                    }
                });
            }

            deleteDuty(entity: interfaces.customs.IGoodsDeclarationLineDutyDisplay) {
                if (!entity.Id) {
                    var index = (<Array<interfaces.customs.IGoodsDeclarationLineDutyDisplay>>this.gvwDuties.data).indexOf(entity);
                    (<Array<interfaces.customs.IGoodsDeclarationLineDutyDisplay>>this.gvwDuties.data).splice(index, 1);

                    this.$timeout(() => {
                        if (this.apiDuties) {
                            this.apiDuties.core.notifyDataChange(this.uiGridConstants.dataChange.ALL);
                        }
                    });

                } else if (entity.Id > 0) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'con.update.dci'
                    },
                        () => {

                            return this.goodsDeclarationLineService.removeDuty(entity.Id).delete((messageHandler: interfaces.applicationcore.IMessageHandler) => {
                                this.generalService.displayMessageHandler(messageHandler);

                                this.$timeout(() => {
                                    this.bsLoadingOverlayService.wrap({
                                        referenceId: 'con.update.dci'
                                    }, () => {
                                        return this.loadGoodsDecLine();
                                    });
                                });

                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                        });
                }
            }

            calculateDuties() {

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'con.update.dci'
                }, () => {
                    return this.SaveAndCalculateDuties().then((result) => {
                        if (!result.HasErrorMessage) {
                            this.$timeout(() => {
                                this.bsLoadingOverlayService.wrap({
                                    referenceId: 'con.update.dci'
                                }, () => {
                                    return this.loadGoodsDecLine();
                                });
                            });
                        }
                    });
                });
            }

            mapDirtyRows() {
                if (this.apiAdditionalCodes) {
                    this.goodsDeclarationLine.AdditionalCodeList = _.map(this.apiAdditionalCodes.rowEdit.getDirtyRows(), (row) => {
                        return row.entity;
                    });
                } else {
                    this.goodsDeclarationLine.AdditionalCodeList = [];
                }

                if (this.apiAdditionalInfos) {
                    this.goodsDeclarationLine.AdditionalInfoList = _.map(this.apiAdditionalInfos.rowEdit.getDirtyRows(), (row) => {
                        return row.entity;
                    });
                } else {
                    this.goodsDeclarationLine.AdditionalInfoList = [];
                }

                if (this.apiQuantities) {
                    this.goodsDeclarationLine.QuantityList = _.map(this.apiQuantities.rowEdit.getDirtyRows(), (row) => {
                        return row.entity;
                    });
                } else {
                    this.goodsDeclarationLine.QuantityList = [];
                }

                if (this.apiDuties) {
                    this.goodsDeclarationLine.DutiesList = _.map(this.apiDuties.rowEdit.getDirtyRows(), (row) => {
                        return row.entity;
                    });
                } else {
                    this.goodsDeclarationLine.DutiesList = [];
                }
            }

            cleanDirtyRows() {
                if (this.apiAdditionalCodes) {
                    this.apiAdditionalCodes.rowEdit.setRowsClean(this.goodsDeclarationLine.AdditionalCodeList);
                }
                if (this.apiAdditionalInfos) {
                    this.apiAdditionalInfos.rowEdit.setRowsClean(this.goodsDeclarationLine.AdditionalInfoList);
                }
                if (this.apiQuantities) {
                    this.apiQuantities.rowEdit.setRowsClean(this.goodsDeclarationLine.QuantityList);
                }
                if (this.apiDuties) {
                    this.apiDuties.rowEdit.setRowsClean(this.goodsDeclarationLine.DutiesList);
                }
            }

            SaveAndCalculateDuties() {
                this.mapDirtyRows();

                return this.goodsDeclarationLineService.calculateGoodsDeclarationDuties().save(this.goodsDeclarationLine, (result: interfaces.applicationcore.IMessageHandler) => {
                    this.$anchorScroll("topAnchor");
                    this.generalService.displayMessageHandler(result);

                    if (!result.HasErrorMessage) {
                        this.cleanDirtyRows();
                    }
                }, (errorResponse) => {
                    this.$anchorScroll("topAnchor");
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            SaveAndCalculateQuantities() {
                this.mapDirtyRows();

                return this.goodsDeclarationLineService.calculateGoodsDeclarationQuantities().save(this.goodsDeclarationLine, (result: interfaces.applicationcore.IMessageHandler) => {
                    this.$anchorScroll("topAnchor");
                    this.generalService.displayMessageHandler(result);

                    if (!result.HasErrorMessage) {
                        this.cleanDirtyRows();
                    }
                }, (errorResponse) => {
                    this.$anchorScroll("topAnchor");
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            SaveGoodsDecLine() {
                this.mapDirtyRows();

                return this.goodsDeclarationLineService.save().save(this.goodsDeclarationLine, (result: interfaces.applicationcore.IMessageHandler) => {
                    this.$anchorScroll("topAnchor");
                    this.generalService.displayMessageHandler(result);

                    if (!result.HasErrorMessage) {

                        this.cleanDirtyRows();

                    }
                }, (errorResponse) => {
                    this.$anchorScroll("topAnchor");
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            //Submit/Update
            Save() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'con.update.dci'
                }, () => {
                    return this.SaveGoodsDecLine().then((result:interfaces.applicationcore.IMessageHandler) => {
                        if (!result.HasErrorMessage) {
                            this.$timeout(() => {
                                this.bsLoadingOverlayService.wrap({
                                    referenceId: 'con.update.dci'
                                }, () => {
                                    
                                    if (!this.goodsDeclarationLine.Id || this.goodsDeclarationLine.Id == 0) {
                                        this.goodsDeclarationLine.Id = Number(result.ID);
                                    }

                                    return this.loadGoodsDecLine(Number(result.ID));
                                });
                            });
                        }
                    });
                });
            }

            SaveClose() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'con.update.dci'
                }, () => {
                    return this.SaveGoodsDecLine().then((result) => {
                        if (!result.HasErrorMessage) {
                            this.$state.go("^");
                        }
                    });
                });
            }

            Close() {
                this.$state.go("^");
            }

            CommodityCodeChange()
            {
                this.isValidTariffCode = false;
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'con.update.dci'
                }, () => {
                    
                    return this.hsCodeService.getDetail().get({
                        countryId: 249,
                        codeType: Enum.EnumTariffCodeType.NationMeasure,
                        simulationDate:  moment.utc().format("YYYYMMDDTHH:mm"),
                        code: this.goodsDeclarationLine.CommodityCode,
                    }, (result: interfaces.tariff.INationalMeasureCodeDetail) => {
                            if (result.TariffCode)
                            {
                                this.goodsDeclarationLine.CommodityCode = result.TariffCode;
                                this.goodsDeclarationLine.DescriptionOfGoods = result.Description;
                                this.isValidTariffCode = true;
                            }
                            else
                            {
                                this.goodsDeclarationLine.DescriptionOfGoods = '';
                            }
                    }, (errorResponse) => {
                        
                    }).$promise;

                });
                
            }

            ViewPreviousConsignment(PreviousConsignmentId:number, PreviousConsignmentIsInbound:boolean){
                var url = "";
                if (PreviousConsignmentIsInbound){
                    url = '#!/ConsignmentImports/UpdateImport/'+PreviousConsignmentId;
                }
                else{                    
                    url = '#!/ConsignmentExports/UpdateExport/'+PreviousConsignmentId;
                }

                window.open(url, '_blank');
            }

    

        };

        angular.module("app").controller("goodsDeclarationLineUpdateCtrl", controllers.customs.goodsDeclarationLineUpdateCtrl);
    }
}